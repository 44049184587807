let ID = "2041ae7817f0466297b88f024db45f1e";

const viewerConfig = {
  embedMode: "LIGHT_BOX",
  dockPageControls: true, 
};

$(".document-read").on("click", function () {
  url = $($(this).children()[0]).text().split(',');
  previewFile(url[0], url[1]);
});

$(".download").on("click", function () {
  url = $($(this).children()[0]).text();
  titulo = $(this).find("h6").text();
  download(url, titulo);
});

function previewFile(fileURL, fileName) {
  var adobeDCView = new AdobeDC.View({
    clientId: ID,
    locale: "es-ES"
  });

  adobeDCView.previewFile({
    content: {
      location: {
        url: fileURL,
      },
    },
    metaData: {
      fileName: fileName + ".pdf"
    }
  }, viewerConfig);
};

function download(fileURL, name) {
  var a = document.createElement("a");
  a.href = fileURL;
  a.setAttribute("download", name);
  a.click();
}
